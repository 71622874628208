<template>
  <DiagnosticSummaryDetail />
</template>

<script>
import DiagnosticSummaryDetail from '@/components/diagnostic/request/DiagnosticSummaryDetail'

export default {
  name: 'DiagnosticSummary',

  components: { DiagnosticSummaryDetail },
}
</script>
