<template>
  <DiagnosticSelectPayment />
</template>

<script>
import DiagnosticSelectPayment from '@/components/diagnostic/request/DiagnosticSelectPayment'
export default {
  name: 'DiagnosticPayment',

  components: { DiagnosticSelectPayment },
}
</script>
