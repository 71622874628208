<template>
  <div>
    <DiagnosticSelectClinic />
  </div>
</template>

<script>
import DiagnosticSelectClinic from '@/components/diagnostic/request/DiagnosticSelectClinic'

export default {
  name: 'DiagnosticService',

  components: { DiagnosticSelectClinic },

}
</script>
