<template>
  <DiagnosticSelectPatient />
</template>

<script>
import DiagnosticSelectPatient from '@/components/diagnostic/request/DiagnosticSelectPatient'

export default {
  name: 'SelectPatient',

  components: { DiagnosticSelectPatient },
}
</script>
